@use "../config/" as *;
.waf-collaboration {
    margin-block: var(--space-5) 0;
    @extend %p-4;
    @extend %mx-2-neg;
    @extend %men-deep_blue-bg;
    .layout-wrapper {
        @extend %gap-4;
        @extend %flex-column;
    }
    .waf-head {
        .head-title {
            @extend %text-center;
            @extend %font-20-pm;
            @extend %uppercase;
            @extend %pure-white-900;
        }
    }
    .client {
        &-list {
            flex-wrap: wrap;
            @extend %gap-5;
            @extend %flex-c-n;
        }
        &-item {
            width: 33.33%;
            height: 5rem;
            @extend %flex-c-c;
            @extend %transparent-bg;
            @extend %relative;
            @extend %px-1;
            &::after {
                content: "";
                right: calc(var(--space-5-neg) / 2);
                width: .1rem;
                @extend %h-100;
                @extend %pure-white-900-bg-2;
                @extend %pos-y-center;
            }
            &:last-child::after,
            &:nth-child(even)::after {
                @extend %d-none;
            }
        }
        &-img {
            object-fit: contain;
            object-position: center;
            @extend %h-100;
        }
    }
}
@include mq(col-md) {
    .waf-collaboration {
        .layout-wrapper {
            max-width: 100%;
            gap: var(--space-6);
        }
        .client {
            &-list {
                flex-wrap: nowrap;
            }
            &-item {
                height: 4rem;
                &:nth-child(even)::after {
                    display: block;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-collaboration {
        .layout-wrapper {
            gap: var(--space-10);
            @include flex-config(null, row, center, center);
        }
        .client {
            &-list {
                gap: var(--space-6);
            }
        }
    }
}