@use "../config/" as *;
.waf-key-events-scorestrip {
  --_logo-box-size: 4.5rem;
  --_logo-img-size: 3rem;
  padding-inline: 0;
  margin-bottom: 0;
  @extend %mx-2-neg;
  .swiper-button-prev,
  .swiper-button-next {
    transform: translate(0, -50%);
    &.swiper-button-disabled {
      @extend %neutral-100-bg;
    }
  }
  .team,
  .match-time {
    @extend %relative
  }
  .swiper {
    position: static;
    @extend %px-3;
    &-button {
      &-prev {
        left: .5rem;
      }
      &-next {
        right: .5rem;
      }
    }
  }
  .waf-head {
    @extend %px-3;
    @extend %mb-3;
  }
  .waf-body {
    @extend %relative;
  }
  .card {
    &-item {
      height: auto;
      border-radius: .7rem;
      width: 100%;
      @extend %px-3;
      @extend %flex-column;
      @extend %pure-white-900-bg;
      @extend %relative;
      &.live .status {
        gap: .8rem;
        @extend %flex-n-c;
        @extend %women-magenta;
        &::before {
          content: "";
          width: .5rem;
          aspect-ratio: 1;
          outline: .01rem solid var(--neutral-100);
          outline-offset: .2rem;
          @extend %circle-radius;
          @extend %women-magenta-bg;
        }
      }
      &.recent .status {
        @extend %error-600;
      }
    }
    &-head {
      @extend %py-3;
      @extend %flex;
      @extend %neutral-500;
      @extend %font-14-pm;
    }
    &-venue::after {
      content: "\00a0"
    }
    &-label::after {
      content: "\00a0"
    }
    &-number {
      white-space: nowrap;
    }
    &-content {
      border-block: .1rem solid clr(neutral-800, 1);
      flex-grow: 1;
      @extend %py-3;
      @extend %flex-c-c;
    }
    &-footer {
      @extend %py-3;
      &-text {
        width: 80%;
        @include truncate-text(1);
        @extend %neutral-900;
        @extend %font-14-pm;
      }
    }
  }
  .team {
    min-height: var(--_logo-box-size);
    @extend %flex-column-c-n;
    @extend %relative;
    @extend %text-right;
    &-wrapper {
      display: grid;
      grid-template-columns: 1fr max-content 1fr;
      @extend %gap-3;
    }
    &-image {
      width: var(--_logo-box-size);
      min-width: var(--_logo-box-size);
      aspect-ratio: 1;
      @include position-combo("y-center", 0);
      @extend %flex-c-c;
      @extend %neutral-50-bg-3;
      @extend %circle-radius;
    }
    &-logo {
      width: var(--_logo-img-size);
      aspect-ratio: 1;
    }
    &-a {
      padding-right: calc(var(--_logo-box-size) + .8rem);
    }
    &-b {
      padding-inline: calc(var(--_logo-box-size) + .8rem) 0;
      text-align: left;
      .team {
        &-image {
          right: unset;
          left: 0;
        }
        &-data {
          justify-content: flex-start
        }
      }
    }
    &-data-wrap {
      @extend %mt-auto;
    }
    &-data {
      @extend %flex-fe-c;
      &:not(:last-child) {
        margin-bottom: .3rem
      }
    }
    &-name {
      word-break: break-word;
      @extend %men-deep_blue;
      // @extend %secondary-800;
      @extend %font-18-pm;
      @extend %uppercase;
    }
    &-score {
      line-height: 1;
      @extend %flex;
      &::after {
        content: "\00a0";
      }
    }
    &-extra {
      @extend %flex;
    }
  }
  .status {
    line-height: 1;
    @include position(null, 1.4rem, 1.5rem);
    @extend %men-deep_blue;
    @extend %font-10-sb;
    @extend %uppercase;
  }
  .date-time {
    white-space: nowrap;
    @extend %ml-auto;
    .date {
      @extend %relative;
      @extend %pr-1;
      &::after {
        content: '';
        height: 1rem;
        width: .1rem;
        @include position(0, -.2rem, null, null, absolute);
        @extend %neutral-500-bg;
      }
    }
    .time {
      @extend %pl-1;
    }
  }
  .match-time {
    @extend %flex-n-c;
  }
  .match-status {
    @extend %d-none;
  }
  .graph-box {
    @extend %d-none;
  }
  .time-text {
    @extend %men-deep_blue;
    @extend %font-20-pb;
    @extend %uppercase;
  }
  .score {
    letter-spacing: var(--letter-space-neg-1);
    @extend %font-18-tsb;
  }
  .overs {
    letter-spacing: var(--letter-space-neg-1);
    @extend %font-14-tr;
  }
  .btn-group,
  .btn-more {
    @include position-combo(inset);
  }
  .btn-more {
    background-color: transparent;
    .btn-text {
      @extend %font-0;
    }
  }
}
@include mq(col-md) {
  .waf-key-events-scorestrip {
    padding-block: var(--space-8);
    overflow: hidden;
    .waf-head {
      padding-inline: 0;
      margin-bottom: var(--space-6);
    }
    .card-item {
      width: calc(50% - 2rem);
    }
    .swiper {
      margin-inline: 0;
      overflow-x: visible;
      overflow-y: clip;
      padding-inline: 0;
      &-button-prev {
        left: -1.7rem;
      }
      &-button-next {
        right: -1.7rem;
      }
    }
  
  }
}
@include mq(col-lg) {
  .waf-key-events-scorestrip {
    .card-item {
      width: calc(33.33% - 2rem);
    }
  }
}