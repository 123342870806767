@use "../config" as *;

.waf-listing {
    &.home-listing {
        --_listing-title: 20;
        --_line-height: 1.2;
        padding-inline: var(--space-3);
        // below is for future reference on what sections
        // are to be hidden by JS. This can be removed once done 
        .waf-footer,
        .thumbnail-swiper,
        .list-group-wrap,
        .waf-child-wrapper,
        .item-type-icon,
        .filter-section,
        .loadmore-wrap,
        .pagination-wrap,
        .article-info,
        .article-description,
        .meta-author,
        .article-readmore,
        .views-section,
        .tagline,
        .primary-tabs,
        .article-commentbox,
        .swiper-pagination,
        .swiper-button-next,
        .swiper-button-prev {
            @extend %d-none;
        }
        @extend %mx-2-neg;
        @include listing-card(vertical);
        .waf-head {
            @extend %mb-3;
            .title {
                @extend %men-deep_blue;
                @extend %pure-white-900-bg;
            }
            .head-wrap {
                border-bottom: .1rem solid var(--pure-white-900);
            }
        }
        .head-tab {
            display: flex;
        }
        .article {
            &-title {
                @extend %neutral-900;
            }
            &-list {
                overflow: auto;
                @extend %mx-3-neg;
                @extend %px-3;
                @extend %flex;
                @extend %gap-3;
            }
            &-item {
                flex-basis: 70%;
                min-width: 70%;
            }
        }
        .meta-category {
            // max-width: 50%;
            max-width: calc(100% - 8rem);
            @extend %d-block;
        }
        a {
            background: transparent;
        }
    }
}