@use "../config" as *;
.waf-gallery-page {
    margin-bottom: 0;
    padding-inline: 0;
    @extend %mx-2-neg;
    .article-head {
        border-bottom: .3rem solid var(--men-deep_blue);
        width: calc(100% - var(--space-6));
        margin-inline: auto;
        @extend %mb-3;
        @extend %flex-n-c;
    }
    .title {
        border-top-right-radius: 1rem;
        line-height: 1;
        font-style: italic;
        padding-bottom: var(--space-1);
        @extend %font-24-pm;
        @extend %men-deep_blue-bg;
        @extend %pure-white-900;
        @extend %p-2-2;
        @extend %uppercase;
    }
    .tagline,
    .article-description,
    .head-wrap,
    .thumbnail-swiper,
    .article-content,
    .article-commentbox {
        @extend %d-none;
    }
    .article-item {
        width: 83%;
        margin-right: var(--space-3);
        @extend %relative;
        &:before {
            content: '';
            border-radius: 1.5rem;
            z-index: var(--z-default);
            transition: 300ms opacity;
            @extend %accent-100-bg-5;
            @extend %w-100;
            @extend %h-100;
            @extend %pos-tl;
        }
        &.swiper-slide-active {
            &::before {
                opacity: 0;
            }
        }
    }
    .img-box {
        border-radius: 1.5rem;
        img {
            object-fit: cover;
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        @extend %pos-y-center;
    }
    .swiper-button {
        &-next {
            right: var(--space-4);
            ;
        }
        &-prev {
            left: var(--space-4);
        }
    }
    .swiper-slide {
        transform: scale(0.95);
        &-active {
            transform: scale(1);
        }
    }
}
@include mq(col-md) {
    .waf-gallery-page {
        padding-block: var(--space-8);
        .title {
            font-size: 3rem;
        }
        .article {
            &-item {
                width: 60%;
                margin-right: var(--space-4);
            }
            &-head {
                width: var(--container-max-width);
                margin-bottom: var(--space-6);
            }
        }
        .swiper-button {
            &-prev {
                left: 17%;
            }
            &-next {
                right: 17%;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-gallery-page {
        .article-item {
            width: 70%;
            margin-right: var(--space-5);
        }
        .swiper-button {
            &-prev {
                left: 13%;
            }
            &-next {
                right: 13%;
            }
        }
    }
}