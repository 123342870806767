@use "../config" as *;

.cookie-block {
    z-index: var(--z-cookie-block);
    @extend %d-none;
    @include position(null,null,var(--header-height),null,fixed);
    .cookie {
        &-wrap {
            width: 90%;
            margin-inline: auto;
            @extend %full-radius;
            @extend %pure-white-900-bg;
        }
        &-close {
            width: 3.4rem;
            height: 3.4rem;
            @extend %men-deep_blue-bg-1;
            @extend %font-0;
            &:after {
                @include icon(close);
                @extend %secondary-900;
            }
        }
        &-head {
            @extend %pt-2;
            @extend %px-5;
            @extend %flex-fe-n;
        }
        &-title {
            @extend %font-24-pb;
            @extend %neutral-900;
            @extend %mb-3;
        }
        &-text {
            line-height: 2rem;
            @extend %font-18-pm;
            @extend %neutral-900;
            a {
                text-decoration: underline;
                @extend %men-blue;
            }
        }
        &-body {
            @extend %mt-2-neg;
            @extend %px-5;
            @extend %mb-5;
        }
        &-footer {
            border-radius: 0 0 var(--full-radius) var(--full-radius);
            @extend %px-5;
            @extend %py-4;
            @extend %men-deep_blue-bg-1;
            .btn-action {
                height: 4rem;
                transition: .3s;
                @extend %px-3;
                @extend %font-10-sb;
                @extend %half-radius;
                @extend %men-blue-bg;
                @extend %pure-white-900;
                &:hover {
                    @extend %men-blue_light-bg;
                }
            }
        }
    }
}
@include mq(col-md) {
    .cookie-block {
        bottom: 6rem;
        right: 3rem;
        .cookie-wrap {
            width: 40rem;
        }
    }
}