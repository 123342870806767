@use "../config" as *;

.quicklink-active {
    .footer-wrap {
        padding-bottom: 10rem;
    }
}
.footer-wrap {
    padding-bottom: 4rem;
}
.footer-top {
    @extend %p-3;
    @extend %men-blue-bg;
    .footer-link {
        flex-shrink: 0;
        @extend %relative;
        &:not(:first-child) {
            &::after {
                content: '';
                width: .1rem;
                left: var(--space-2-neg);
                @extend %h-80;
                @extend %pos-y-center;
                @extend %pure-white-900-bg-2;
            }
        }
        &-list {
            list-style: none;
            overflow-x: auto;
            @extend %gap-4;
            @extend %flex;
            @extend %pl-0;
        }
        &-anchor {
            @extend %transparent-bg;
        }
        &-text {
            @extend %capitalize;
            @extend %pure-white-900;
            @extend %font-14-pm;
        }
    }
}
.footer-bottom {
    @extend %p-4-6;
    @extend %men-deep_blue-bg;
    .footer-copyright-wrap {
        @extend %gap-3;
        @extend %flex-column;
    }
    .copyright-text {
        line-height: 2.4rem;
        @extend %text-center;
        @extend %capitalize;
        @extend %pure-white-900;
        @extend %font-14-pm;
    }
    .si-logo {
        @extend %gap-3;
        @extend %flex-c-c;
        a {
            @extend %transparent-bg;
        }
        .text {
            @extend %pure-white-900;
            @extend %font-14-pm;
        }
        .logo {
            height: 4rem;
        }
    }
}
.ecc-partners {
    @extend %mx-2-neg;
    @extend %py-7;
    @extend %men-deep_blue-bg;
    .title {
        @extend %text-center;
        @extend %pure-white-900;
        @extend %uppercase;
        @extend %font-20-pm;
    }
    .partner {
        &-head {
            @extend %mb-8;
        }
        &-logo {
            width: 12rem;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
        &-anchor {
            height: 100%;
            width: 100%;
        }
        &-item {
            @extend %flex-c-c;
            @extend %relative;
            &::after {
                content: "";
                width: .1rem;
                right: -1rem;
                @extend %absolute;
                @extend %h-100;
                @extend %neutral-10-bg-4;
            }
            &:last-child::after {
                @extend %d-none;
            }
        }
        &-list {
            justify-content: center;
            @include card-count(2, var(--space-4), true);
        }
        &-section {
            @extend %p-7-4;
        }
        &-title {
            .partner {
                &-list {
                    width: 100%;
                }
                &-logo {
                    width: 25rem;
                    flex-shrink: 0;
                }
            }
        }
        &-main {
            .partner-item {
                &:nth-child(even)::after {
                    @extend %d-none;
                }
            }
        }
        &-charity {
            .partner {
                &-logo {
                    width: 100%;
                }
            }
        }
        &-supported {
            .partner {
                &-head {
                    @extend %flex-c-n;
                    @extend %relative;
                    &:before {
                        content: '';
                        height: .1rem;
                        left: 0;
                        @extend %w-100;
                        @extend %pos-y-center;
                        @extend %neutral-10-bg-4;
                    }
                    .title {
                        width: max-content;
                        z-index: var(--z-default);
                        @extend %px-3;
                        @extend %men-deep_blue-bg;
                    }
                }
                &-logo {
                    height: 6rem;
                }
                &-item {
                    &:nth-child(even)::after {
                        @extend %d-none;
                    }
                }
            }
        }
    }
}
@include mq(col-md) {
    .footer-top {
        padding: var(--space-4) var(--container-white-space);
        .footer-link {
            &:not(:first-child) {
                &::after {
                    left: var(--space-4-neg);
                }
            }
            &-list {
                justify-content: center;
                gap: var(--space-8);
            }
            &-text {
                font-size: 1.8rem;
            }
        }
    }
    .footer-bottom {
        padding-inline: var(--container-white-space);
        .copyright-text {
            font-size: 1.8rem;
        }
        .si-logo {
            .text {
                font-size: 1.8rem;
            }
        }
    }
}
@include mq(col-lg) {
    .footer-wrap {
        padding-bottom: 0;
    }
    .quicklink-active {
        .footer-wrap {
            padding-bottom: 6rem;
        }
    }
    .footer-bottom {
        padding-inline: var(--container-white-space);
        .footer-copyright-wrap {
            @include flex-config(null, row, space-between, center);
        }
    }
    .ecc-partners {
        .title {
            font-size: 2.4rem;
        }
        .partner {
            &-item {
                width: 15%;
                &:not(:last-child)::after {
                    height: 60%;
                }
                &.andalucia {
                    .partner-logo {
                        width: 9rem;
                    }
                }
            }
            &-title {
                .partner-logo {
                    width: 30rem;
                }
            }
            &-charity {
                .partner-item {
                    width: 22%;
                }
            }
            &-main {
                .partner-item {
                    &:not(:last-child):nth-child(even)::after {
                        display: block;
                    }
                }
                .partner-list {}
            }
            &-supported {
                .partner-item {
                    &:not(:last-child):nth-child(even)::after {
                        display: block;
                    }
                }
            }
            &-section {
                padding-inline: 0;
            }
            &-section-divide {
                @include flex-config(flex,null,center);
                width: 55%;
                margin-inline: auto;
                gap: 4rem;
                .partner-section  {
                    flex: 1;
                    position: relative;
                    &:not(:last-child) {
                        &::after {
                            content: '';
                            width: .1rem;
                            height: 50%;
                            background: hsl(var(--hsl-neutral-10) / 0.4);
                            @include position(null,-2rem,1rem,null);
                        }
                    }
                }
                .partner-item {
                    width: 65%;
                }
            }
        }
    }
}