@use "../config" as *;
// margin and padding 0 for all elements and border box
// 1. as best practices in * there should be our font so that it will apply on all elements as we already know that buttons dont inherit font family 
// 2. making all margin 0 so that we will apply our styling according to need
// 3. making all padding 0 so that we will apply our styling according to need
* {
    font-family: $font-primary; // 1
    @extend %m-0; // 2
    @extend %p-0; // 3
}
*,
*::before,
*::after {
    box-sizing: border-box; // we dont have to calculate the widths and height if padding or border is added
}
// 1. base font size and scroll behaviour for smooth scroll animation
// 2. for smooth scrolling 
html {
    font-size: var(--base-font-size); // 1
    scroll-behavior: smooth; // 2
}
// body
// 1. As a best practice, apply a default `background-color`.
// 2. As a best practice, apply a default `color` so that if text color changes just change body text color.
body {
    font-family: $font-primary, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji"; // with backup fonts
    // font-weight: $body-font-weight; // normal font weight for all texts
    font-weight: normal;
    // line-height: $default-line-height; // default line height for every text elements 
    font-size: $body-font-size; // body font size
    letter-spacing: .02em;
    @extend %accent-300-bg;
    @extend %neutral-900; // 2
}
hr {
    border-top: 0.1rem solid;
    @extend %neutral-900;
}
:where(span, p, a) {
    line-height: 1;
}
:where(small, label) {
    @extend %font-12;
}
:where(big) {
    @extend %font-16;
}
:where(sub, sup) {
    @extend %font-10;
}
:where(h1, h2, h3, h4, h5, h6) {
    @extend %mb-2;
}
:where(h1) {
    @extend %font-28-pb;
}
:where(h2) {
    @extend %font-24-pb;
}
:where(h3) {
    @extend %font-20-pb;
}
:where(h4) {
    @extend %font-18-pb;
}
:where(h5) {
    @extend %font-16-pb;
}
:where(h6) {
    @extend %font-14-pb;
}
:where(p) {
    @extend %mb-2;
}
:where(a) {
    text-decoration: none;
    @extend %neutral-900;
    &:hover {
        text-decoration: none;
    }
}
ul,
ol {
    @extend %pl-2;
}
a:where(:not([href]):not([class])) {
    &,
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}
:where(blockquote) {
    margin: 0 0 1rem;
}
button {
    border: none;
    @extend %neutral-900;
    @extend %transparent-bg;
    &:where(:not(:disabled)) {
        cursor: pointer;
    }
    &:where(:focus) {
        outline: none;
    }
}
//  textarea will not extend as a width
textarea {
    resize: vertical; // 1
}
:where(input) {
    background-color: transparent;
    padding: var(--space-3);
    border: .1rem solid var(--neutral-900);
    width: 100%;
    &:focus-within {
        outline: none;
    }
}
iframe {
    border: 0;
}
[hidden] {
    display: none;
}
@media screen and (min-width: $tablet-min-width) {
    :where(h1) {
        font-size: 3rem;
    }
    :where(h2) {
        font-size: 2.6rem;
    }
    :where(h3) {
        font-size: 2.4rem;
    }
    :where(h4) {
        font-size: 2rem;
    }
    :where(h5) {
        font-size: 1.8rem;
    }
    :where(h6) {
        font-size: 1.6rem;
    }
}