@use "../config" as *;

.waf-modal {
	border: none;
	width: var(--window-inner-width);
	height: var(--window-inner-height);
	z-index: var(--z-modal);
	// overflow: auto;
	align-items: center;
	justify-content: center;
	@extend %d-none;
	@extend %neutral-900-bg-9;
	@include position( 0, 0, 0, 0, fixed);
	&.active {
		@extend %flex;
	}
	.modal-wrapper {
		height: calc(100% - 14rem);
		width: 90%;
		@extend %half-radius;
		@extend %p-3;
		@extend %neutral-50-bg;
	}
	.modal-head {
		@extend %flex-fe-n;
		.btn-close {
			width: 2rem;
			height: 2rem;
			@extend %flex-c-c;
			&::before {
				@extend %neutral-900;
				@include icon(close);
			}
			.btn-text {
				@extend %d-none;
			}
		}
	}
	.modal-footer {
		@extend %flex-c-n;
	}
}
.waf-compare-modal {
	.btn-compare {
		height: 4rem;
		border-radius: 2rem;
		padding-inline: 5rem;
		@extend %men-blue-bg;
		.btn-text {
			@extend %uppercase;
			@extend %pure-white-900;
			@extend %font-10-sb;
		}
	}
}
@include mq(col-lg) {
	.waf-modal {
		&.waf-compare-modal {
			.modal-wrapper {
				width: 65%;
				padding: var(--space-5);
				height: calc(100% - 11rem);
			}
		}
	}
}