$colors: (
	primary:( //
		50: (229, 100%, 91%), //#D2DAFF
		100: (233, 9%, 82%), // #CCCDD4
		200: (47, 22%, 70%), // #C4BDA3 
		300: (47, 37%, 59%), // #BDAC70 
		400: (46, 43%, 52%), // #B9A04E 
		500: (44, 53%, 47%), // #B89638 
		600: (42, 57%, 45%), // #B38D31
		700: (39, 62%, 42%), // #AE8029 
		800: (37, 67%, 39%), // #A77321 
		900: (33, 81%, 35%), // #9F6011 
	),
	secondary:( //
		50:(228, 22%, 91%), //#E3E5ED
		100:(227, 38%, 82%), //#BEC6E2
		200:(226, 40%, 70%), //#94A2D1
		300:(225, 41%, 59%), //#6C82C2
		400:(224, 44%, 51%), //#4A67B8
		500:(218, 84%, 40%), //#1050BB
		600:(221, 71%, 38%), //#1C47A6
		700:(220, 79%, 34%), //#123E99
		800:(218, 100%, 28%), //#00358E
		900:(213, 70%, 12%), //#091C33
	),
	neutral: ( //
		10: (0, 0%, 91%), //#E8E8E8
		50: (0, 0%, 83%), //#D4D4D4
		100: (0, 0%, 81%), //#CFCFCF
		200: (0, 0%, 79%), //#C9C9C9
		300: (0, 0%, 73%), //#BBBBBB
		400: (0, 0%, 60%), //#989898
		500: (0, 0%, 48%), //#7B7B7B
		600: (0, 0%, 33%), //#555555
		700: (0, 0%, 26%), //#424242
		800: (0, 0%, 15%), //#272727
		900: (0, 0%, 0%), //#000000
	),
	accent: ( //
		100: (217, 84%, 7%), //#030F22
		200: (37, 76%, 57%), //#E4A43D
		300: (0, 0%, 93%), //#EDEDED
		400: (214, 76%, 10%), //#06172D
		500: (215, 76%, 13%) //#081E3C
	),
	success: ( //
		900: (145, 75%, 41%), //#1AB85B
	),
	warning: ( //
		900: (40, 100%, 48%), //#F5A500
	),
	error: ( //
		600: (0, 84%, 45%),
		900: (360, 81%, 58%), //#EA3C3D
	),
	pure-white: ( //
		900: (0, 0%, 100%), //#ffffff
	),
	spider:( //
		1s: (34, 100%, 47%), // #F18700
		2s: (73, 47%, 51%), // #A3BD47
		3s: (309, 100%, 55%), // #FF18DD
		4s: (149, 100%, 32%), // #00A24D
		5s: (206, 100%, 46%), // #0087ED 
	)
);
$aspect-ratio: (
	4 3,
	3 4,
	16 9,
	1 1,
	2 3,
	9 16,
	21 9,
	18 9,
	18 6,
	6 4
);
$flex-width: (
	10,
	20,
	25,
	30,
	33,
	40,
	50,
	60,
	70,
	80,
	100
);
$font-detail: (
	
	Thunder:( //
		pm:(file-name:Thunder-Medium, weight: 500, style:normal),
		pb:(file-name:Thunder-Bold, weight: 700, style:normal),
	),
	WhirlyBirdie:( //
		sr:(file-name:WhirlyBirdieVariable, weight: 75, style:normal),
		sb:(file-name:WhirlyBirdieVariable, weight: 100, style:normal),
	),
	OpenSans:(
		tr:(file-name:OpenSans-Regular, weight: 400, style:normal),
		tm:(file-name:OpenSans-Medium, weight: 500, style:normal),
		tsb:(file-name:OpenSans-SemiBold, weight: 600, style:normal),
		tb:(file-name:OpenSans-Bold, weight: 700, style:normal),
	)
);
$directions: (
	t: -top,
	r: -right,
	b: -bottom,
	l: -left,
	x: -inline,
	y: -block,
);
$flex-spacing: (
	-sa: space-around,
	-sb: space-between,
	-se: space-evenly,
	-fe: flex-end,
	-fs: flex-start,
	-c:center,
	-s:stretch,
	-u:unset,
	-n:null
);
$media-query: (
	col: 300px,
	col-sm: 576px,
	col-md: 768px,
	col-lg:992px,
	col-xl: 1200px,
	col-xxl: 1400px,
);
$zindex: (
	patterns:-1,
	zero:0,
	default: 1,
	header:100,
	search:2,
	hamburger:5,
	overlay:1,
	video-icon:2,
	showcase:3,
	icon:4,
	timer:5,
	modal:100,
	modal-close:21,
	modal-swiper:22,
	modal-share:22,
	key-events-sticky: 11,
	follow-us: 7,
	player-profile-thumbnail: 1,
	stat-listing-select: 2,
	scrolltop: 99,
	bg-color: -1,
	cookie-block: 100,
	score-data: -1,
	bg-silhouette: -1,
	modal-head: 2,
	rado-image: 2,
	rado-clock: 2,
	default-neg: -1,
	quicklink-section: 10,
);
$icons: (
	"draw-edit": "\ea71",
	"login": "\e846",
	"play-video": "\e84c",
	"rss": "\e85d",
	"double-chev-left": "\e867",
	"double-chev-right": "\e868",
	"share": "\e874",
	"mail": "\e885",
	"play": "\e89a",
	"web": "\e8a8",
	"chain": "\e8ae",
	"trophy": "\e8f9",
	"redirect": "\e8fd",
	"crown": "\e903",
	"allrounder": "\e905",
	"keeper": "\e906",
	"bowler": "\e907",
	"batsman": "\e908",
	"bat": "\e909",
	"stumpings": "\e90a",
	"wickets": "\e90b",
	"tick": "\e90f",
	"add": "\e910",
	"bowler-left": "\e911",
	"run-out": "\e916",
	"bowler-right": "\e918",
	"match": "\e919",
	"catch": "\e91a",
	"mail-fill": "\e91e",
	"soundcloud": "\e91f",
	"stats": "\e944",
	"helmet": "\e945",
	"news": "\e946",
	"home": "\e947",
	"top-right": "\e971",
	"close": "\e9be",
	"chevron-right": "\e9c5",
	"chevron-left": "\e9cd",
	"author": "\e9f8",
	"quote": "\e9f9",
	"phone": "\e9fc",
	"success": "\ea00",
	"live-key": "\ea19",
	"upcoming-key": "\ea1a",
	"recent-key": "\ea1b",
	"error": "\eafd",
	"reset": "\eb03",
	"information-sign": "\eb70",
	"chevron-down": "\e972",
	"chevron-up": "\e932",
	"search": "\ec16",
	"hamburger": "\e933",
	"ECT10": "\e940",
	"ECC": "\e941",
	"ECS": "\e942",
	"ECL": "\e943",
	"user-profile": "\ea91",
	"sms": "\ea93",
	"right-fill-arrow":'\e89a',
	"facebook": "\e976",
	"youtube": "\e975",
	"tiktok": "\ea29",
	"whatsapp": "\e995",
	"twitter": "\f099",
	"instagram": "\e974",
	"linkedin": "\f0e1",
	"copylink": "\e996",
	"app-store": "\e93c",
	"play-store": "\e93b",
	"telegram": "\e973",
	"reset-button": "\eb03",
	"re-direct": "\e9a4",
	"telegram-circle": "\e990",
	"soundcloud-circle": "\e991",
	"icon-fixtures": "\e8c5",
);
$social: (
	"facebook": "\e976",
	"youtube": "\e975",
	"tiktok": "\ea29",
	"whatsapp": "\e995",
	"twitter": "\f099",
	"instagram": "\e974",
	"linkedin": "\f0e1",
	"copylink": "\e996",
	"app-store": "\e93c",
	"play-store": "\e93b",
	"telegram": "\e973",
	"soundcloud": "\e91f",
);
$player-compare-icon: (
	"match": "\e919",
	"over": "\e918",
	"run-out": "\e916",
	"stumping": "\e90a",
	"catch": "\e91a",
	"wicket": "\e90b",
	"run": "\e909",
);