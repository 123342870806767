@use "../config" as *;
 
.waf-clock-banner {
    padding-inline: var(--space-1);
    margin-bottom: 0;
    .waf-body {
        height: 58rem;
    }
    .card {
        &-thumbnail {
            height: calc(45% + var(--space-4));
            @extend %pt-4;
            @extend %gap-1;
            @extend %flex-column-c-n;
            @extend %relative;
            @extend %pure-white-900-bg;
            &::after {
                content: '';
                height: calc(100% + 7rem);
                z-index: var(--z-default);
                pointer-events: none;
                @extend %w-100;
                @include position(null,null,var(--space-7-neg),0);
                @include background(null, "clock/clock-white-bg.png", no-repeat center/cover);
            }
            .rado {
                height: 4.4rem;
                width: 13.5rem;
                z-index: var(--z-rado-image);
                object-fit: contain;
                @extend %mt-2;
                @extend %mx-auto;
                @extend %relative;
            }
            .card-image {
                width: 23rem;
                height: 23rem;
                z-index: var(--z-rado-clock);
                @extend %mx-auto;
                @extend %h-100;
                @extend %relative;
                .clock {
                    @extend %w-100;
                    @extend %h-100;
                    object-fit: contain;
                }
                &::after {
                    content: '';
                    width: 1rem;
                    height: 9.5rem;
                    animation-name: tick;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                    animation-duration: 60s;
                    @include position(6.3rem,null,null,10.5rem);
                    @include background(null, "clock/clock-second.png", no-repeat center/cover);
                }
            }
        }
        &-content {
            height: 55%;
            isolation: isolate;
            padding: var(--space-14) var(--space-4) var(--space-4);
            @extend %relative;
            &:before {
                content: '';
                z-index: var(--z-patterns);
                pointer-events: none;
                @extend %men_dark_bt;
                @extend %h-100;
                @extend %pos-bl;
                @extend %w-100;
            }
            .title-wrap {
                @extend %flex;
                @extend %gap-4;
            }
            .title-image {
                width: 7rem;
                height: auto;
                flex-shrink: 0;
            }
            .title {
                line-height: 2.6rem;
                @extend %font-24-pb;
                @extend %pure-white-900;
            }
            .btn-visit {
                min-height: 4rem;
                border: .1rem solid var(--primary-800);
                transition: .3 all;
                @extend %gap-2;
                @extend %flex-c-c;
                @extend %px-3;
                @extend %half-radius;
                @extend %primary-800-bg;
                .btn-text {
                    @extend %capitalize;
                    @extend %font-12-pb;
                    @extend %pure-white-900;
                }
                &::after {
                    @extend %pure-white-900;
                    @include icon(re-direct);
                }
                &:hover {
                    background: transparent;
                    border: .1rem solid var(--pure-white-900);
                }
            }
        }
        &-wrapper {
            @extend %h-100;
        }
    }
    .countdown {
        &-item {
            width: 5.4rem;
            height: 5rem;
            border: .1rem solid var(--secondary-400);
            @extend %relative;
            @extend %half-radius;
            @extend %flex-column-c-c;
            @extend %men-blue-bg;
            &:not(:last-child) {
                &::after {
                    content: ':';
                    right: var(--space-3-neg);
                    pointer-events: none;
                    @extend %pure-white-900;
                    @extend %pos-y-center;
                }
            }
        }
        &-count {
            @extend %font-20-pm;
            @extend %pure-white-900;
        }
        &-label {
            @extend %font-16-pm;
            @extend %pure-white-900;
        }
        &-list {
            border-block: .1rem solid var(--neutral-500);
            @extend %my-3;
            @extend %py-3;
            @extend %gap-5;
            @extend %flex;
        }
    }
}
@include mq(col-md) {
    .waf-clock-banner{
        .card-content {
            padding-inline: var(--space-14);
        }
    }
}
@include mq(col-lg) {
    .waf-clock-banner {
        .waf-head {
            z-index: var(--z-default);
        }
        .waf-body {
            height: 56rem;
        }
        .card {
            &-wrapper {
                display: flex;
            }
            &-thumbnail,&-content {
                height: 100%;
            }
            &-thumbnail {
                width: 40%;
                padding-top: 0;
                padding-left: 8rem;
                &::after {
                    height: 100%;
                    width: calc(100% + 16rem);
                    bottom: 0;
                    @include background(null, "clock/clock-white-bg-web.png", no-repeat center/cover);
                }
                .card-image {
                    width: 43rem;
                    height: 43rem;
                    &::after {
                        height: 18rem;
                        top: 11.6rem;
                        left: 20rem;
                    }
                }
                .rado {
                    width: 43rem;
                    height: 8rem;
                }
            }
            &-content {
                width: 60%;
                padding-inline: 22rem 6rem;
                padding-block: 0;
                @include flex-config(flex,column,center);
                &:before {
                    @include gradient('men_dark', 'rl');
                }
                .title {
                    font-size: 30px;
                    line-height: 34px;
                    &-image {
                        width: 10rem;
                        height: 12rem;
                    }
                    &-wrap {
                        align-items: center;
                    }
                }
                .btn-visit {
                    width: max-content;
                }
            }
        }
        .countdown {
            &-list {
                gap: var(--space-8);
                margin-block: var(--space-8);
                width: max-content;
            }
            &-item {
                width: 8rem;
                height: 7.5rem;
                &:not(:last-child) {
                    &::after {
                        right: var(--space-4-neg);
                    }
                }
            }
            &-count {
                font-size: 3rem;
            }
            &-label {
                font-size: 2.4rem;
            }
        }
    }
}