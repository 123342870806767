@use "../config" as *;
.article-item {
    width: 100%;
}
.waf-listing {
    --z-listing: 2;
    --z-reaction: 3;
    padding: 0 var(--space-1);
    .waf-head {
        @extend %mt-4;
    }
    .waf-body {
        @extend %py-4;
    }
    .article {
        &-wrap {
            @extend %flex-column;
        }
        &-thumbnail {
            @extend %w-100;
        }
        &-item {
            @extend %relative;
            @extend %full-radius;
            @extend %hidden;
        }
        &-title {
            font-weight: 500;
            @extend %mt-0;
            @include truncate(var(--_listing-line, 2), var(--_listing-title, 18), var(--_line-height, 1.5));
            @extend %neutral-900;
        }
        &-content {
            z-index: var(--z-listing);
            flex-wrap: wrap;
            @extend %flex;
            @extend %relative;
            @extend %transparent-bg;
            @extend %w-100;
            @extend %p-2;
            @extend %neutral-900;
            > a {
                @extend %w-100;
            }
            .readmore {
                @extend %font-0;
                @extend %w-100;
                @extend %h-100;
                @extend %pos-tl;
            }
            .reaction-section {
                z-index: var(--z-reaction);
                width: 3rem;
                @extend %relative;
                @extend %mt-auto;
                @extend %ml-auto;
            }
            &-meta {
                .meta {
                    &::after {
                        @extend %neutral-900-bg;
                    }
                }
            }
        }
        &-meta {
            width: calc(100% - 3.5rem);
            flex-wrap: wrap;
            @extend %p-0;
            @extend %mt-auto;
            @extend %mb-1;
            @extend %gap-1;
            @extend %flex-n-fs;
            .meta {
                @extend %neutral-400;
                @extend %relative;
                @extend %m-0;
                &:not(:first-child) {
                    color: var(--neutral-400);
                    @extend %pr-2;
                    &:after {
                        content: "";
                        width: 0.1rem;
                        height: 1rem;
                        @include position-combo(y-center, 0);
                    }
                }
                &::after {
                    @extend %neutral-400-bg;
                }
                &-category,
                &-author {
                    // max-width: 34%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @extend %hidden;
                    @extend %font-10-sr;
                }
                &-date {
                    order: 1;
                    @extend %font-10-sr;
                    span{
                        @extend %font-10-sr; 
                    }
                }
            }
        }
    }
}
//for news and video-listing pages
.waf-listing {
    &.waf-component {
        .waf-footer,
        .thumbnail-swiper,
        .list-group-wrap,
        .waf-child-wrapper,
        .item-type-icon,
        .loadmore-wrap,
        .article-info,
        .article-description,
        .meta-author,
        .meta-category,
        .article-readmore,
        .views-section,
        .tagline,
        .primary-tabs,
        .article-commentbox,
        .swiper-pagination,
        .swiper-button-next,
        .swiper-button-prev,
        .head-tab {
            @extend %d-none;
        }
    }
    &.listing-page {
        --_listing-line: 2;
        --_listing-title: 18;
        --_line-height: 1.5;
        .waf-body {
            @extend %relative;
            @extend %pure-white-900-bg;
            @extend %half-radius;
            @extend %mt-4;
            @extend %mx-3-neg;
            @extend %px-3;
        }
        .article {
            &-list {
                @extend %flex-column;
                @extend %gap-4;
                .social-share .icon-b-share::before {
                    @extend %neutral-400;
                }
                // &.first-list {
                //     @extend %pt-3;
                // }
            }
            &-item {
                &:first-of-type {
                    --_listing-line: 2;
                    --_listing-title: 30;
                    --_line-height: 1.2;
                }
                &:not(:first-of-type) {
                    @include listing-card(horizontal);
                }
                .img-box {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    height: 100%;
                }
            }
            &-meta {
                @extend %neutral-400;
                @extend %font-12-pm;
            }
        }
    }
}
// For video listing cards
.waf-listing {
    &.video-listing {
        .icon-time-wrapper {
            top: -1.5rem;
            @extend %p-1;
            @extend %primary-900-bg;
            @extend %pure-white-900;
            @extend %pos-x-center;
            @extend %rounded-radius;
            .timestamp {
                @extend %font-14-tsb;
                p {
                    @extend %font-14-tsb;
                }
            }
            .item-type-icon {
                display: inline-block;
                width: unset;
                height: auto;
                @extend %pure-white-900;
                @extend %mx-1;
                @include icon(play-video, 14);
                &::before {
                    @extend %pure-white-900;
                }
            }
        }
        .article {
            &-content {
                @extend %neutral-50-bg-5;
            }
        }
    }
}
.pagination {
    @extend %flex-c-c;
    @extend %mt-8;
    @extend %gap-2;
    &-result {
        @extend %d-none;
    }
    &-number {
        // @extend %font-20-tsb;
        @extend %mx-3;
        @extend %flex-c-c;
        @extend %gap-2;
        * {
            @extend %font-20-tsb;
            @extend %neutral-500;
        }
        .active {
            @extend %neutral-900;
        }
    }
    .nav {
        &-first,
        &-last,
        &-arrow {
            width: 3rem;
            height: 3rem;
            border: .1rem solid var(--neutral-900);
            @extend %circle-radius;
            @extend %flex-c-c;
            &::before,
            &::after {
                @extend %neutral-900;
            }
            span {
                @extend %neutral-900;
                @extend %font-24-pm;
                @extend %d-none;
            }
            &.disabled {
                border: 0.1rem solid var(--neutral-500);
                pointer-events: none;
                &::before,
                &::after {
                    @extend %neutral-500;
                }
                span {
                    @extend %neutral-500;
                }
            }
        }
        &-first {
            &::before {
                @include icon(chevron-left, 10);
            }
            &::after {
                @include icon(chevron-left, 10);
            }
        }
        &-last {
            &::before {
                @include icon(chevron-right, 10);
            }
            &::after {
                @include icon(chevron-right, 10);
            }
        }
        &-left {
            &::before {
                @include icon(chevron-left, 10);
            }
        }
        &-right {
            &::before {
                @include icon(chevron-right, 10);
            }
        }
    }
}
@include mq(col-md) {
    .pagination {
        &-number {
            font-size: 2.4rem;
        }
        .nav {
            &-first,
            &-last,
            &-arrow {
                width: auto;
                height: 3.4rem;
                border-radius: 50vmax;
                padding-inline: var(--space-2);
                span {
                    display: block;
                    // color: var(--neutral-500);
                    text-transform: uppercase;
                    padding-top: var(--space-1);
                }
            }
            &-first,
            &-left {
                span {
                    margin-left: var(--space-2);
                    order: 1;
                }
            }
            &-last,
            &-right {
                span {
                    margin-right: var(--space-2);
                    order: -1;
                }
            }
        }
    }
    .waf-listing {
        .waf-head .title {
            font-size: 3rem;
        }
        .waf-body {
            padding: var(--space-3);
            &::before {
                width: 100%;
                left: 0;
            }
        }
        &.listing-page {
            --_listing-line: 2;
            --_listing-title: 24;
            --_line-height: 1.17;
            margin-top: var(--space-2);
            .waf-body {
                margin-inline: 0;
            }
            .article {
                &-list {
                    @include listing-card(vertical);
                    @include grid(4);
                }
                &-item {
                    &:first-child {
                        --_listing-title: 24;
                        --_line-height: 1.17;
                    }
                    &:not(:first-of-type) {
                        @include listing-card(vertical);
                    }
                }
            }
        }
    }
}