@use "../config" as *;
html {
    @extend %w-100;
    &.no-scroll,
    &.no-scroll body {
        height: var(--window-inner-height);
        touch-action: none;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: auto;
        @extend %hidden;
    }
}
[v-cloak] {
    visibility: hidden;
}
// .grecaptcha-badge {
//     visibility: hidden;
// }
.waf-component {
    @extend %p-4-3;
    @extend %mb-4;
}
:where(.img-box) {
    @extend %neutral-300-bg;
    @extend %half-radius;
    @extend %hidden;
}
img {
    display: block;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
    object-fit: cover;
    object-position: top;
    @extend %w-100;
}
[class^="img-"] {
    @extend %relative;
    img {
        @extend %h-100;
        @extend %pos-tl;
        &[alt] {
            font-size: 1.2rem;
            @extend %neutral-900-7;
        }
    }
}
@each $width,
$height in $aspect-ratio {
    .img-#{$width}by#{$height} .img-box {
        padding-bottom: calc(($height / $width) * 100%);
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    @extend %m-0;
}
/* Firefox */
input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}
.desktop {
    @extend %d-none;
}
.mobile {
    @extend %d-block;
}
.no-data-wrap {
    @extend %my-6;
    @extend %flex-c-n;
    .no-data {
        padding: var(--space-3) var(--space-4) var(--space-2);
        @extend %uppercase;
        @extend %men-blue-bg;
        @extend %pure-white-900;
        @extend %half-radius;
        @extend %font-20-pm;    
    }
}
@each $width in $flex-width {
    .m-w-#{$width} {
        width: calc($width * 1%);
        &-gap {
            width: calc((#{$width * 1%} - var(--space-1)));
        }
    }
}
//project css start
/* web setup css done */
/* project common css start */
.main-wrap {
    padding-top: var(--header-before-height);
}
.home-page {
    .main-wrap {
        padding-top: 0;
    }
    .secondary-header {
        @extend %d-none;
    }
    .site-header {
        &::before {
            content: unset;
        }
    }
}
// max-width style 
:where(.layout-wrapper) {
    max-width: var(--container-max-width);
    margin: auto;
}
// waf head common styles 
:where(.waf-head) {
    .head-wrap {
        border-bottom: .3rem solid var(--men-deep_blue);
        @extend %w-100;
        @extend %flex-sb-c;
        @extend %gap-1;
    }
    .title {
        border-top-right-radius: 1rem;
        align-self: flex-end;
        font-style: italic;
        padding-bottom: var(--space-1);
        @extend %p-2;
        @extend %men-deep_blue-bg;
        @extend %pure-white-900;
        @extend %font-24-pm;
        @extend %uppercase;
    }
    .head-tab {
        order: 2;
        flex-direction: row-reverse;
        flex-grow: 1;
        list-style-type: none;
        @extend %pl-0;
        @extend %flex-sb-c;
        @extend %gap-1;
        li {
            &:first-child a {
                @extend %capitalize;
                @extend %flex-n-c;
                @extend %gap-1;
                @extend %men-deep_blue;
                @extend %font-10-sb;
                &::after {
                    @include icon(chevron-right, 8);
                }
            }
        }
        a {
            background-color: transparent;
        }
    }
    .promo-section {
        @extend %gap-1;
        @extend %flex-n-c;
        .promo-text {
            flex-shrink: 0;
            @extend %font-14-pm;
            @extend %neutral-900;
        }
        .promo-logo {
            height: 2rem;
        }
    }
}
// buttons style starts 
.loadmore {
    padding: var(--space-2) var(--space-8);
    border: 0.1rem solid;
    @extend %rounded-radius;
    @extend %neutral-900;
}
.loadmore-wrap {
    margin: var(--space-4) auto;
    display: block;
    width: max-content;
}
.scroll-top {
    z-index: var(--z-scrolltop);
    @extend %d-none;
    @include position(null, 3rem, 8rem, null, fixed);
    .scroll-top-btn {
        width: 3.4rem;
        aspect-ratio: 1/1;
        @extend %relative;
        @extend %men-blue_light-bg;
        @extend %circle-radius;
        @extend %flex-c-c;
        &::after {
            @include icon(chevron-up, 8);
            @extend %neutral-900;
            @extend %pos-center;
        }
    }
}
.event-page {
    .main-wrap {
        padding-top: calc(var(--header-height) + 9.5rem);
    }
}
img {
    &.image-error {
        @include background(null, "players/0.png", center / contain no-repeat);
        @extend %h-100;    
    }
}
//safari workaround for base font size
@media only screen and (max-width: $tablet-min-width) {
    :root {
        -webkit-text-size-adjust: none;
    }
}
// buttons style starts 
@include mq(col-md) {
    .mobile {
        display: none;
    }
    .desktop {
        display: block;
    }
    .waf-head {
        .head-wrap {
            gap: var(--space-4);
        }
        .title {
            padding: var(--space-2) var(--space-3) var(--space-1);
            font-size: 3rem;
        }
        li {
            &:first-child a {
                font-size: 1.2rem;
            }
        }
        .promo-section {
            gap: var(--space-2);
           
            .promo-logo {
                height: 2.2rem;
            }
        }
    }
    @each $width in $flex-width {
        .w-#{$width} {
            width: calc($width * 1%);
            &-gap {
                width: calc((#{$width * 1%} - var(--space-1)));
            }
        }
    }
    .main-wrap {
        padding-top: var(--header-before-height);
    }
}
.body-wrap {
    min-height: calc(var(--window-inner-height) - var(--header-height));
}
@include mq(col-lg) {
    .event-page {
        .main-wrap {
            padding-top: calc(var(--header-height) + 13.5rem);
        }
    }
}