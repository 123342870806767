@use "../config" as *;

.waf-key-events-sticky {
    position: fixed;
    width: calc(100% - var(--space-6));
    height: var(--key-events);
    overflow: hidden;
    padding: 0;
    padding-top: 2.75rem;
    border-radius: 0 0 4rem 4rem;
    padding-inline: var(--header-space);
    margin-bottom: 0;
    transition: .5s ease-in-out;
    @include position(calc(var(--header-space) + 2.75rem),var(--header-space),null,var(--header-space),fixed);
    @extend %w-100;
    @extend %men_dark_bt;
    .layout-wrapper {
        @extend %h-100;
        @extend %flex-n-c;
    }
    .head-wrap {
        border-bottom: 0;
        @extend %flex-c-c;
        @extend %h-100;
    }
    .title {
        background: transparent;
        @extend %h-100;
        @extend %p-0;
        @extend %font-14-pm;
        @extend %uppercase;
        @extend %flex-c-c;
        @extend %gap-2;
        &::after {
            @include icon(top-right,12);
        }
    }
    .waf {
        &-head {
            width: 8rem;
            @extend %mb-0;
            @extend %h-100;
        }
        &-body {
            width: calc(100% - 8rem);
            @extend %relative;
            @extend %h-100;
            @extend %flex;
        }
    }
    .article {
        &-item {
            width: max-content;
            // border-right: 0.1rem solid var(--neutral-50);
            flex-shrink: 0;
            @extend %relative;
            @extend %flex-n-c;
            @extend %px-2;
            @extend %py-1;
            @extend %gap-2;
            &:not(:last-child) {
                &::after {
                    content: '';
                    right: 0;
                    width: .1rem;
                    @extend %pos-y-center;
                    @extend %h-60;
                    @extend %neutral-10-bg;
                }
            }
            &.live {
                .article-status {
                    @extend %pure-white-900;
                    @extend %success-900-bg;
                    &:before {
                        content: '';
                    }
                }
            }
            &.upcoming {
                .article-status {
                    @extend %neutral-50-bg;
                    @extend %men-deep_blue;
                }
            }
            &.recent {
                .article-status {
                    @extend %error-600-bg;
                    @extend %pure-white-900;
                }
            }
            &.completed {
                .article-status {
                    @extend %pure-white-900-bg;
                }
            }
        }
        &-status {
            line-height: 1.1;
            gap: .5rem;
            padding-block: .3rem;
            @extend %flex-sb-c;
            @extend %font-10-sr;
            @extend %men-deep_blue;
            @extend %uppercase;
            @extend %rounded-radius;
            @extend %px-2;
            &::before {
                width: 1rem;
                height: 1rem;
                @extend %flex;
                @include background(null, "svg/live.svg", no-repeat center/cover);
            }
        }
        &-list {
            //check margin if issuse caused
            @extend %flex;
            @extend %w-100;
            @extend %h-100;
            @extend %relative;
        }
        &-title {
            @extend %font-10-sr;
            @extend %capitalize;
            @extend %pure-white-900;
            @include truncate-text(1, 10);
            height: 1.2rem;
        }
        &-btn {
            display: inline-block;
            background: transparent;
            @extend %w-100;
            @extend %h-100;
            @extend %pos-tr;
            .text {
                @extend %d-none;
            }
        }
    }
    .swiper {
        position: static;
        width: calc(100% - 4rem);
        &-button-prev,
        &-button-next {
            width: 2rem;
            height: 2rem;
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%) translateZ(0);
            will-change: transform;
            @extend %circle-radius;
            @extend %men-deep_blue;
            @extend %flex-c-c;
            @extend %men-blue_light-bg;
        }
        &-button-next {
            @extend %pos-tr;
            &::after {
                @include icon(chevron-right,12);
                @extend %men-deep_blue;
            }
            &.swiper-button-disabled {
                pointer-events: none;
                @extend %men-blue_light-bg;
                &::after {
                    opacity: .3;
                    @extend %men-deep_blue;
                }
            }
        }
        &-button-prev {
            @extend %pos-tl;
            &::after {
                @include icon(chevron-left,12);
                @extend %men-deep_blue;
            }
            &.swiper-button-disabled {
                pointer-events: none;
                @extend %men-blue_light-bg;
                &::after {
                    opacity: .3;
                    @extend %men-deep_blue;
                }
            }
        }
    }
}

@include mq(col-md) {
    .waf-key-events-sticky {
        .waf {
            &-head {
                width: 10rem;
            }
            &-body {
                width: calc(100% - 11rem);
            }
        }
        .title {
            padding: 0;
            font-size: 1.4rem;
        }
        .layout-wrapper {
            max-width: var(--content-width);
        }
        .article-title {
            @include truncate-text(1, 12);
        }
        .swiper {
            width: calc(100% - 5rem);
        }
    }
}

@include mq(col-lg) {
    .waf-key-events-sticky {
        top: calc(var(--key-events) + var(--space-1));
        height: var(--key-events);
        padding-top: 3.35rem;
        .title {
            font-size: 1.6rem;
        }
        .waf-head {
            width: 11rem;
        }
        .swiper {
            width: calc(100% - 6rem);
        }
        .swiper-button {
            &-prev,
            &-next {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}