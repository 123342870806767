@use "../config" as *;
.waf-our-events {
    @extend %mx-2-neg;
    .swiper {
        @extend %mx-3-neg;
    }
    .article {
        &-title {
            line-height: 1;
            @include truncate-text(2);
            @extend %font-20-pm;
            @extend %mt-2;
            @extend %neutral-900;
        }
        &-list {
            overflow: auto;
            box-sizing: border-box;
            @extend %mt-3;
            @extend %flex;
            @extend %gap-3;
            @extend %px-3;
            @include listing-card(vertical);
        }
        &-item {
            flex-basis: 70%;
            min-width: 70%;
        }
    }
}
@include mq(col-md) {
    .waf-our-events {
        --_listing-title: 24;
        --_line-height: 1.17;
        .article {
            &-list {
                padding: 0;
                box-sizing: content-box;
                overflow: unset;
                gap: var(--space-4);
            }
            &-item {
                min-width: unset;
                flex-basis: unset;
                width: calc(25% - var(--space-4));
            }
        }
        .waf-body {
            margin-inline: calc(var(--container-white-space) * -1);
        }
        .swiper {
            padding-inline: var(--container-white-space);
            margin: 0;
            &-container-initialized .article-list {
                gap: 0;
            }
        }
    }
}