@use "../config" as *;
.waf-select-box {
    --z-dropdown: 5;
    height: 3rem;
    border-radius: 0 0 var(--full-radius) var(--full-radius);
    @extend %pl-2;
    @extend %pr-5;
    @extend %w-100;
    @extend %relative;
    @extend %neutral-50-bg;
    .dropdown-close {
        @extend %d-none;
    }
    &.active {
        .select-box-wrap {
            @include dropdown(open);
        }
        .selected-title {
            &::after {
                transform: rotate(180deg);
            }
        }
    }
    .selected-title {
        cursor: pointer;
        @extend %font-14-pm;
        @extend %w-100;
        @extend %h-100;
        @extend %flex-sb-c;
        .title {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            align-self: center;
            padding-right: .4rem;
            @extend %transparent-bg;
            @extend %uppercase;
            @extend %m-0;
            @extend %p-0;
            @extend %neutral-900;
            @extend %font-10-sr;
        }
        .sub-title {
            @extend %d-none;
        }
        &::after {
            transition: 0.3s;
            right: var(--space-1);
            @extend %neutral-900;
            @extend %pos-y-center;
            @include icon(chevron-down, 10);
        }
    }
    .select-box-wrap {
        min-width: 15rem;
        box-shadow: -0.2rem 0.2rem 1rem -0.5rem clr(neutral-900, 6);
        z-index: var(--z-dropdown);
        top: 100%;
        border-radius: 0 0 var(--half-radius) var(--half-radius);
        @include dropdown(close);
        @extend %neutral-50-bg;
        @extend %pos-tr;
    }
    .select-list {
        overflow: auto;
        max-height: 30rem;
        overflow-x: hidden;
        @extend %pl-0;
        .list-item {
            @extend %font-10-sr;
            @extend %w-100;
            @extend %relative;
            @extend %flex-n-c;
            @extend %p-2-2;
            @extend %text-left;
            &:not(:last-child) {
                &::before {
                    content: "";
                    height: 0.1rem;
                    @extend %w-100;
                    @extend %neutral-900-bg-2;
                    @extend %pos-bl;
                }
            }
            &.active {
                @extend %pure-white-900;
                @extend %men-deep_blue-bg;
                .list-item-text {
                    @extend %pure-white-900;
                }
            }
            a {
                color: inherit;
                @extend %font-10-sr;
                @extend %flex-n-c;
                @extend %w-100;
            }
        }
    }
}