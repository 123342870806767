@use "./map" as *;
// scss variables
$base-space: 0.5rem;
$max-space-count: 20; // maximum count variable generation
$default-line-height: 1.6;
$body-font-size: 1.4rem;
$body-font-weight: 500;
// media query variables
$small-mobile-min-width: 0px;
$small-mobile-max-width: 360px;
$medium-mobile-min-width: 576px;
$mobile-max-width: 767px;
$tablet-min-width: 768px;
$tablet-max-width: 991px;
$desktop-min-width: 992px;
$large-desktop-min-width: 1200px;
$xl-desktop-min-width: 1600px;
// font variables 
$font-primary: "Thunder", sans-serif;
$font-secondary: "WhirlyBirdie", sans-serif;
$font-tertiary:  "OpenSans", sans-serif;
$font-icon: "waf-icon-font";
//  version variables
$image-version: "2.2";
$font-version: "1211990";
// css variables
:root {
    @each $color, $varients in $colors {
        @each $varient, $hsl in $varients {
            $h: nth($hsl, 1);
            $s: nth($hsl, 2);
            $l: nth($hsl, 3);
            --#{$color}-#{$varient}: hsl(#{$h} #{$s} #{$l});
            --hsl-#{$color}-#{$varient}: #{$h} #{$s} #{$l};
        }
    }
    // non color variables
    --base-font-size: 62.5%;
    --container-max-width: 100%;
    --content-width: var(--window-inner-width);
    --vh: 1vh;
    --vw: 1vw;
    --window-inner-height: calc(var(--vh, 1vh) * 100);
    --window-inner-width: calc(var(--vw, 1vw) * 100);
    --container-white-space: calc((var(--window-inner-width) - var(--container-max-width)) / 2);
    // space variable
    @for $i from 1 through $max-space-count {
        --space-#{$i}: #{$base-space * $i};
        --space-#{$i}-neg: #{$base-space * $i * -1};
    }
    --space-0:0;
    --letter-space-neg-1: -.1rem;
    // use
    // --space-1 = 5px
    // --space-2 = 10px.... till --space-12 = 60px
    // this is according to base space variable
    --full-radius: 1rem;
    --half-radius: calc(var(--full-radius) / 2);
    // **********this is just example for naming convention so please remove it and use it***********
    // height varible
    --swiper-button-width: 2.4rem;
    --logo-width: 5.4rem;
    --logo-height: 7rem;
    // Reskin Added start
    --reskin-logo-width: 12rem;
    --reskin-logo-height: 3.8rem;
    // Reskin Added end
    --logo-bottom-space: 3rem;
    --header-height: 5.5rem;
    --social-height: 6.8rem;
    --key-events: 6rem;
    --header-space: 1.5rem;
    --header-before-height: 11.75rem; 
    --official-Partner-module: 22rem;
    // zindex variable ********** assending order *******************
    @each $name, $index in $zindex {
        --z-#{$name}:#{$index};
    }
}
@media (min-width: $tablet-min-width) {
    :root {
        --container-max-width: 72rem;
        --filter-height: 7rem;
        --swiper-button-width: 3.4rem;
        --logo-width: 8.5rem;
        --logo-height: 11rem;
        // Reskin Added start
        // --reskin-logo-width: 16rem;
        // --reskin-logo-height: 5.3rem;
        // Reskin Added end
        // --primary-header-height: 8rem;
        // --header-height: var(--primary-header-height);
    }
}
@media (min-width: $desktop-min-width) {
    :root {
        --container-max-width: 96rem;
        --official-Partner-module: 5rem;
    }
}
@media (min-width: $large-desktop-min-width) {
    :root {
        --container-max-width: 1140px;
        --header-height: 7rem;
        --social-height: 4rem;
        --key-events: 7rem;
        --reskin-logo-width: 16rem;
        --reskin-logo-height: 5.3rem;
        --header-before-height: 16.5rem;
    }
}
@media (min-width: $xl-desktop-min-width) {
    :root {
        --container-max-width: 140rem;
    }
}