@use "../config/" as *;
@each $icon,
$value in $social {
    .icon-#{$icon} {
        &::before {
            content: $value;
        }
    }
}
.article-list {
    .social {
        &-share {
            width: 3rem;
            height: 3rem;
            &.active {
                .social-share-wrap {
                    @extend %flex;
                }
            }
            .share {
                &-icon {
                    width: 3rem;
                    height: 3rem;
                    aspect-ratio: 1/1;
                }
                &-label {
                    @extend %d-none;
                }
            }
            .icon-b-share {
                &::before {
                    @include icon(share);
                }
            }
            .icon-b-close {
                &::before {
                    @include icon(close);
                    @extend %pure-white-900;
                }
            }
            &-wrap {
                @extend %d-none;
                @extend %pos-tr;
            }
        }
        &-items {
            border-radius: var(--half-radius) 0 0 var(--half-radius);
            @extend %flex;
            @extend %neutral-900-bg;
        }
        &-icon {
            width: 3rem;
            height: 3rem;
            aspect-ratio: 1/1;
            &::before {
                width: 3rem;
                height: 3rem;
                font: 1.8rem $font-icon;
                @extend %flex-c-c;
                @extend %pure-white-900;
            }
        }
        &-wrap {
            @extend %flex;
            .close {
                width: 3rem;
                height: 3rem;
                border-radius: 0 var(--half-radius) var(--half-radius) 0;
                @extend %neutral-900-bg;
            }
        }
    }
}