@use "variables" as *;
@use "mixins" as *;
@use "map" as *;
@use "animation" as *;
// font sizes start
@for $size from 8 through 60 {
  $font-size : calc($size * .1rem);
  // Just font size
  %font-#{$size} {
    font-size: $font-size;
  }
  // for all size
  @each $font-family, $font-variation in $font-detail {
    @each $font-alias, $font-type in $font-variation {
      $font-family-file-name: map-get($map: $font-type, $key: "file-name");
      $font-weight: map-get($map: $font-type, $key: "weight");
      $font-style: map-get($map: $font-type, $key: "style");
      %font-#{$size}-#{$font-alias} {
        font: $font-weight #{$size * .1rem} $font-family ;
      }
    }
  }
}
%font-0 {
  font-size: 0;
}
// HOW TO USE
// @extend %font-12;
// @extend %font-12-pr;
// @extend %font-16-pb;
// @extend %font-20-pm;
//colors start
@each $color, $varients in $colors {
  @each $varient, $hsl in $varients {
    @for $opacity from 1 through 9 {
      $opacity-value: calc($opacity * 0.1);
      %#{$color}-#{$varient}-#{$opacity} {
        color:hsl(var(--hsl-#{$color}-#{$varient})/#{$opacity-value});
      }
      %#{$color}-#{$varient}-bg-#{$opacity} {
        background-color:hsl(var(--hsl-#{$color}-#{$varient})/#{$opacity-value});
      }
    }
    %#{$color}-#{$varient} {
      color:var(--#{$color}-#{$varient});
    }
    %#{$color}-#{$varient}-bg {
      background-color:var(--#{$color}-#{$varient});
    }
  }
}
%transparent-bg {
  background-color: transparent;
}
%none-bg {
  background: none;
}
// %transparent-bg // HOW TO USE
// @extend %neutral-100;
// @extend %neutral-100-9;
// @extend %neutral-100-bg;
// @extend %neutral-100-bg-9;
// convention used t=top,l=left,b=bottom,r=right,y=block[top-bottom],x=inline[left-right],m=margin,p=padding
%relative {
  position: relative;
}
%absolute {
  position: absolute;
}
%fixed {
  position: fixed;
}
// absolute start
%pos-y-center {
  @include position-combo(y-center);
}
%pos-x-center {
  @include position-combo(x-center);
}
%pos-center {
  @include position-combo(center);
}
%pos-tl {
  @include position-combo(tl);
}
%pos-tr {
  @include position-combo(tr);
}
%pos-bl {
  @include position-combo(bl);
}
%pos-br {
  @include position-combo(br);
}
// position fixed
%pos-y-center-fix {
  @include position-combo(y-center, null, null, fixed);
}
%pos-x-center-fix {
  @include position-combo(x-center, null, null, fixed);
}
%pos-center-fix {
  @include position-combo(center, null, null, fixed);
}
%pos-tl-fix {
  @include position-combo(tl, null, null, fixed);
}
%pos-tr-fix {
  @include position-combo(tr, null, null, fixed);
}
%pos-bl-fix {
  @include position-combo(bl, null, null, fixed);
}
%pos-br-fix {
  @include position-combo(br, null, null, fixed);
}
// absolute end
// border-radius starts
%full-radius {
  border-radius: var(--full-radius)
}
%half-radius {
  border-radius: var(--half-radius);
}
%circle-radius {
  border-radius: 50%;
}
%rounded-radius {
  border-radius: 50vmax;
}
// border-radius ends
// convention used t=top,l=left,b=bottom,r=right,y=block[top-bottom],x=inline[left-right],m=margin,p=padding
// spacing starts
@each $direction,
$direction-value in $directions {
  @for $space from 0 through $max-space-count {
    %m#{$direction}-#{$space} {
      margin#{$direction-value}: var(--space-#{$space});
    }
    %p#{$direction}-#{$space} {
      padding#{$direction-value}: var(--space-#{$space});
    }
    %m#{$direction}-#{$space}-neg {
      margin#{$direction-value}: var(--space-#{$space}-neg);
    }
    %p#{$direction}-#{$space}-neg {
      padding#{$direction-value}: var(--space-#{$space}-neg);
    }
  }
  %m#{$direction}-auto {
    margin#{$direction-value}: auto;
  }
  %p#{$direction}-auto {
    padding#{$direction-value}: auto;
  }
}
@for $i from 0 through $max-space-count {
  @for $j from 0 through $max-space-count {
    %m-#{$i}-#{$j} {
      margin: var(--space-#{$i}) var(--space-#{$j});
    }
    %p-#{$i}-#{$j} {
      padding: var(--space-#{$i}) var(--space-#{$j});
    }
  }
}
//how to use
// @extend %mt-2; margin-top
// @extend %mx-2; margin-inline
// @extend %my-2; margin-block
// @extend %m-2-4; margin-block-inline
@for $space from 0 through $max-space-count {
  %m-#{$space} {
    margin: var(--space-#{$space});
  }
  %p-#{$space} {
    padding: var(--space-#{$space});
  }
  %gap-#{$space} {
    gap: var(--space-#{$space});
  }
  %m-#{$space}-neg {
    margin: var(--space-#{$space}-neg);
  }
  %p-#{$space}-neg {
    padding: var(--space-#{$space}-neg);
  }
  %gap-#{$space}-neg {
    gap: var(--space-#{$space}-neg);
  }
}
%m-auto {
  margin: auto;
}
%p-auto {
  padding: auto;
}
//how to use
// @extend %m-1
// @extend %p-2
// @extend %m-1-neg
// @extend %p-2-neg
// spacing ends
// For width & height
@for $i from 1 through 10 {
  %h-#{calc($i * 10)} {
    height: calc($i * 10%);
  }
  %w-#{calc($i * 10)} {
    width: calc($i * 10%);
  }
}
// text transform start
%uppercase {
  text-transform: uppercase;
}
%lowercase {
  text-transform: lowercase;
}
%capitalize {
  text-transform: capitalize;
}
// text transform end
// text alignment start
%text-center {
  text-align: center;
}
%text-left {
  text-align: left;
}
%text-right {
  text-align: right;
}
// text alignment end
// display default start
%d-none {
  display: none;
}
%d-block {
  display: block;
}
// display default end
// Flex classes start
%flex {
  display: flex;
}
%flex-column {
  display: flex;
  flex-direction: column;
}
%flex-end {
  display: flex;
  justify-content: flex-end;
}
%flex-start {
  display: flex;
  justify-content: flex-start;
}
%flex-se {
  display: flex;
  justify-content: space-evenly;
}
%flex-c {
  display: flex;
  align-items: center;
}
%flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}
%flex-sb {
  display: flex;
  justify-content: space-between;
}
@each $justify,
$justify-value in $flex-spacing {
  @each $align,
  $align-value in $flex-spacing {
    %flex#{$justify}#{$align} {
      @include flex-config(flex, null, #{$justify-value}, #{$align-value});
    }
    %flex-column#{$justify}#{$align} {
      @include flex-config(flex, column, #{$justify-value}, #{$align-value});
    }
  }
}
%flex-wrap {
  flex-wrap: wrap;
}
%flex-nowrap {
  flex-wrap: nowrap;
}
// Flex classes end
// shortform
// 	sa: space-around,
// 	sb: space-between,
// 	se: space-evenly,
// 	fe: flex-end,
// 	fs: flex-start,
// 	c:center,
// 	s:stretch,
// 	u:unset,
// 	n:null
//how to use
// @extend %flex;
// @extend %flex-column;
// @extend %flex-null-c;
// @extend %flex-sb-c;
// @extend %flex-c-fs;
// @extend %flex-column-fs-c;
// @extend %flex-column-c-sb;
%transition {
  transition: 0.3s;
}
%btn-fill {
  @extend %transition;
  @extend %p-2-6;
  @extend %primary-900-bg;
  @extend %pure-white-900;
  @extend %font-12-pb;
  @extend %half-radius;
  &:hover {
    @extend %accent-300-bg;
    @extend %neutral-900;
  }
  &:disabled,
  &.disabled {
    pointer-events: none;
    @extend %neutral-300-bg;
    @extend %neutral-900-5;
  }
}
%btn-outline {
  @include border(1, primary);
  @extend %transition;
  @extend %primary-900;
  @extend %p-2-6;
  @extend %font-12-pb;
  @extend %half-radius;
  &:hover {
    @include border(1, accent);
    @extend %accent-100-bg;
    @extend %neutral-900;
  }
  &:disabled,
  &.disabled {
    pointer-events: none;
    @extend %neutral-300-bg;
    @extend %neutral-900-5;
  }
}
// Overflow start
%hidden {
  overflow: hidden;
}
%visible {
  overflow: visible;
}
%overflow {
  overflow: auto;
}
%contain-paint {
  contain: paint;
}
%overflow-x-clip {
  overflow-x: clip;
}
%overflow-y-clip {
  overflow-y: clip;
}
%overflow-y-scroll {
  overflow-y: scroll;
}
// Overflow end