@use "../config" as *;

.waf-latest-news {
    &.home-listing {
        @extend %py-6;
        @extend %main_tlbr;
        .article {
            &-content {
                @extend %pure-white-900-bg;
            }
        }
        .waf-head li:first-child a {
            @extend %pure-white-900;
        }
        .promo-text{
            @extend %pure-white-900;
        }
    }
}

@include mq(col-md) {
    .waf-latest-news {
        &.home-listing {
            .article {
                &-item {
                    flex-basis: calc(50% - 0.8rem);
                    min-width: calc(50% - 0.8rem);
                }
            }
        }
        &.waf-latest-news{
            padding-inline: 0;
            .article{
                &-list{
                    padding: 0;
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                }
                &-item {
                    width: 100%;
                    flex-basis: 100%;
                }
            }
        }
    }
}

@include mq(col-xl) {
    .waf-latest-news {
        &.home-listing {
            margin-inline: var(--space-2-neg);
            .article {
                &-list {
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    grid-template-rows: auto auto;
                    gap: 20px;
                    grid-template-areas: "card-1 card-2 card-3"
                        "card-1 card-2 card-4";
                }

                &-item {
                    min-width: unset;

                    &:first-child {
                        grid-area: card-1;
                    }

                    &:nth-child(2) {
                        grid-area: card-2;

                        .article-wrap {
                            @include flex-config(flex, column);
                        }

                        .article-thumbnail {
                            flex-basis: 100%;
                            max-width: 100%;
                        }
                    }

                    &:nth-child(3),
                    &:nth-child(4) {
                        @include listing-card(horizontal);

                        .article-wrap {
                            height: 100%;
                            @include flex-config()
                        }

                        .img-box {
                            height: 100%;
                        }

                        .article-thumbnail {
                            flex-basis: 100%;
                            max-width: 50%;
                        }
                    }

                    &:nth-child(3) {
                        grid-area: card-3;
                    }

                    &:nth-child(4) {
                        grid-area: card-4;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}