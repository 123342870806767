@use "../config" as *;
.swiper {
	&-button-next,
	&-button-prev {
		width: var(--swiper-button-width);
		aspect-ratio: 1/1;
		@extend %men-deep_blue-bg;
		@extend %rounded-radius;
		@extend %flex-c-c;
		&.swiper-button-disabled {
			cursor: not-allowed;
			opacity: 1;
			@extend %pure-white-900-bg;
			&::after {
				@extend %men-deep_blue;
			}
		}
		&::after {
			font: 1rem/1 $font-icon;
			@extend %pure-white-900;
		}
	}
	&-button-prev {
		&::after {
			@include icon(chevron-left);
		}
	}
	&-button-next {
		&::after {
			@include icon(chevron-right);
		}
	}
	&-pagination-bullet {
		width: 1rem;
		height: .2rem;
		border-radius: var(--half-radius);
		background-color: clr(neutral-900, 7);
		&-active {
			width: 2rem;
			height: .4rem;
			background-color: clr(neutral-900);
		}
	}
}
@include mq(col-md) {
	.swiper {
		&-button-next,
		&-button-prev {
			&::after {
				font-size: 1.4rem;
			}
		}
	}
}