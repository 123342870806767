// shimmer animation starts
@keyframes shimmerAnimation {
  100% {
    transform: translateX(100%);
  }
}
// shimmer animation starts
@keyframes tick {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes opennow {
  0% {
    transform: translateX(-0.3rem);
  }
  100% {
    transform: translateX(0.3rem);
  }
}