$reskin-colors: (
	men: (
		'turquoise':(180, 100%, 50%),//#00FFFF
		'blue_light':(194, 100%, 49%),//#00BEFA
		'blue':(231, 94%, 49%),//#0729F4
		'deep_blue':(222, 100%, 23%),//#002273
	),
	women:(
		'magenta': (300, 100%, 50%),//#FF00FF
		'lilac': (289, 68%, 56%),//#BF41DB
		'purple': (274, 100%, 38%),//#7000C3
		'violet': (276, 100%, 22%),//#440072
	)
);

$gradient-colors: (
  main: (
    lr: linear-gradient(90deg, var(--men-blue_light) 0%, var(--men-blue) 50%, var(--women-purple) 75%, var(--women-lilac) 100%),
    tb: linear-gradient(180deg, var(--men-blue_light) 0%, var(--men-blue) 50%, var(--women-purple) 75%, var(--women-lilac) 100%),
    rl: linear-gradient(90deg, var(--women-lilac) 0%, var(--women-purple) 25%, var(--men-blue) 50%, var(--men-blue_light) 100%),
    bt: linear-gradient(180deg, var(--women-lilac) 0%, var(--women-purple) 25%, var(--men-blue) 50%, var(--men-blue_light) 100%),
    tlbr: linear-gradient(135deg, var(--men-blue_light) 0%, var(--men-blue) 50%, var(--women-purple) 75%, var(--women-lilac) 100%),
    trbl: linear-gradient(225deg, var(--men-blue_light) 0%, var(--men-blue) 50%, var(--women-purple) 75%, var(--women-lilac) 100%),
    brtl: linear-gradient(135deg, var(--women-lilac) 0%, var(--women-purple) 25%, var(--men-blue) 50%, var(--men-blue_light) 100%),
    bltr: linear-gradient(225deg, var(--women-lilac) 0%, var(--women-purple) 25%, var(--men-blue) 50%, var(--men-blue_light) 100%)
  ),
  men: (
    lr: linear-gradient(90deg, var(--men-turquoise) 0%, var(--men-blue) 100%),
    tb: linear-gradient(180deg, var(--men-turquoise) 0%, var(--men-blue) 100%),
    rl: linear-gradient(90deg, var(--men-blue) 0%, var(--men-turquoise) 100%),
    bt: linear-gradient(180deg, var(--men-blue) 0%, var(--men-turquoise) 100%),
    tlbr: linear-gradient(135deg, var(--men-turquoise) 0%, var(--men-blue) 100%),
    trbl: linear-gradient(225deg, var(--men-turquoise) 0%, var(--men-blue) 100%),
    brtl: linear-gradient(315deg, var(--men-turquoise) 0%, var(--men-blue) 100%),
    bltr: linear-gradient(45deg, var(--men-turquoise) 0%, var(--men-blue) 100%)
  ),
  men_dark: (
    lr: linear-gradient(90deg, var(--men-blue) 0%, var(--men-deep_blue) 100%),
    tb: linear-gradient(180deg, var(--men-blue) 0%, var(--men-deep_blue) 100%),
    rl: linear-gradient(270deg, var(--men-blue) 0%, var(--men-deep_blue) 100%),
    bt: linear-gradient(0deg, var(--men-blue) 0%, var(--men-deep_blue) 100%),
    tlbr: linear-gradient(135deg, var(--men-blue) 0%, var(--men-deep_blue) 100%),
    trbl: linear-gradient(225deg, var(--men-blue) 0%, var(--men-deep_blue) 100%),
    brtl: linear-gradient(315deg, var(--men-blue) 0%, var(--men-deep_blue) 100%),
    bltr: linear-gradient(45deg, var(--men-blue) 0%, var(--men-deep_blue) 100%)
  ),
  women: (
    lr: linear-gradient(90deg, var(--women-magenta) 0%, var(--women-purple) 100%),
    tb: linear-gradient(180deg, var(--women-magenta) 0%, var(--women-purple) 100%),
    rl: linear-gradient(90deg, var(--women-purple) 0%, var(--women-magenta) 100%),
    bt: linear-gradient(180deg, var(--women-purple) 0%, var(--women-magenta) 100%),
    tlbr: linear-gradient(135deg, var(--women-magenta) 0%, var(--women-purple) 100%),
    trbl: linear-gradient(225deg, var(--women-magenta) 0%, var(--women-purple) 100%),
    brtl: linear-gradient(315deg, var(--women-magenta) 0%, var(--women-purple) 100%),
    bltr: linear-gradient(45deg, var(--women-magenta) 0%, var(--women-purple) 100%)
  ),
  women_dark: (
    lr: linear-gradient(90deg, var(--women-purple) 0%, var(--women-violet) 100%),
    tb: linear-gradient(180deg, var(--women-purple) 0%, var(--women-violet) 100%),
    rl: linear-gradient(90deg, var(--women-violet) 0%, var(--women-purple) 100%),
    bt: linear-gradient(180deg, var(--women-violet) 0%, var(--women-purple) 100%),
    tlbr: linear-gradient(135deg, var(--women-purple) 0%, var(--women-violet) 100%),
    trbl: linear-gradient(225deg, var(--women-purple) 0%, var(--women-violet) 100%),
    brtl: linear-gradient(315deg, var(--women-purple) 0%, var(--women-violet) 100%),
    bltr: linear-gradient(45deg, var(--women-purple) 0%, var(--women-violet) 100%)
  )
);
// use @extend %women_dark_tlbr;

:root {
    @each $category, $colors in $reskin-colors {
        @each $color-name, $hsl in $colors {
            $h: nth($hsl, 1);
            $s: nth($hsl, 2);
            $l: nth($hsl, 3);
            --#{$category}-#{$color-name}: hsl(#{$hsl});
            --hsl-#{$category}-#{$color-name}: #{$h} #{$s} #{$l};
        }
    }
}

// reskin gradient mixin

@mixin gradient($category, $direction) {
    $gradient: map-get(map-get($gradient-colors, $category), $direction);

    @if $gradient {
        background: #{$gradient};
    }

    @else {
        @warn "Gradient not found for #{$category}-#{$direction}";
    }
}

// use
// @include gradient('men', 'lr');
// @include gradient('men_dark', 'bt');
// @include gradient('women', 'tlbr');
// @include gradient('women_dark', 'tr');

@each $category, $directions in $gradient-colors {
    @each $direction, $gradient in $directions {
        %#{$category}_#{$direction} {
            @include gradient($category, $direction);
        }
    }
}
// use @extend %women_dark_tlbr;


@each $color, $varients in $reskin-colors {
  @each $varient, $hsl in $varients {
    @for $opacity from 1 to 9 {
      %#{$color}-#{$varient}-#{$opacity} {
        color:hsl(var(--hsl-#{$color}-#{$varient})/#{$opacity * 0.1});
      }
      %#{$color}-#{$varient}-bg-#{$opacity} {
        background-color:hsl(var(--hsl-#{$color}-#{$varient})/#{$opacity * 0.1});
      }
    }
    %#{$color}-#{$varient} {
      color:var(--#{$color}-#{$varient});
    }
    %#{$color}-#{$varient}-bg {
      background-color:var(--#{$color}-#{$varient});
    }
  }
}
// use @extend %men-deep_blue-bg;