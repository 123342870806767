@use "../config" as *;

.filter-section {
    --z-filter-bg: -1;
    max-width: 15rem;
    min-width: 10rem;
    @include position(-6rem,0,null,null);
    .more-filters,.filter-actions {
        @extend %d-none;
    }
    .sub-title {
        pointer-events: none;
    }
    .filter-wrap {
        @extend %flex;
    }
}