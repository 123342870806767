@use "../config/" as *;

.waf-newsletter {
    padding-inline: var(--space-1);
    padding-block: var(--space-0);
    @extend %full-radius;
    @extend %main_lr;
    @extend %my-0;
    @extend %mx-1;
    @extend %relative;
    &::before {
        content: '';
        pointer-events: none;
        @extend %w-100;
        @extend %h-100;
        @extend %pos-center;
        @include background(null,"reskin-pattern/pattern-1.png", no-repeat center/130% 130%);
    }
    .layout-wrapper {
        @extend %p-6-3;
    }
    .waf-head {
        .sub-title {
            font-style: italic;
            @extend %uppercase;
            @extend %pure-white-900;
            @extend %font-40-pb;
        }
        .text {
            padding-block: var(--space-2) var(--space-3);
            line-height: 2.4rem;
            @extend %pure-white-900;
            @extend %font-18-pm;
        }
        .head-image {
            .image {
                width: auto;
                height: 6rem;
            }
        }
    }
    .waf-body {
        @extend %mt-3;
    }
    .social {
        &-list {
            list-style: none;
            @extend %gap-2;
            @extend %flex-n-c;
            @extend %pl-0;
        }
        &-text {
            @extend %font-0;
        }
        &-item {
            width: 2.4rem;
            height: 2.4rem;
            @extend %flex-c-c;
        }
        &-link {
            font-family: $font-icon;
            width: 2.4rem;
            height: 2.4rem;
            font-size: 1.8rem;
            @extend %flex-c-c;
            @extend %pure-white-900;
            @extend %transparent-bg;
            &::before {
                font-size: 1.2rem;
            }
        }
    }
    .form-data {
        @extend %flex-column-n-c;
        @extend %gap-4;
        .form-subscribe {
            @extend %relative;
            @extend %w-100;
            &:after {
                right: var(--space-3);
                @extend %pos-y-center;
                @extend %pure-white-900;
            }
            &.name {
                &:after {
                    @include icon(user-profile);
                }
            }
            &.email {
                &:after {
                    @include icon(sms);
                }
            }
        }
        .form-input {
            height: 4rem;
            border: .1rem solid var(--pure-white-900);
            opacity: .6;
            @extend %pure-white-900;
            @extend %transparent-bg;
            @extend %font-10-sr;
            @extend %pl-3;
            @extend %pr-6;
            @extend %half-radius;
            @extend %w-100;
            &::placeholder {
                @extend %pure-white-900;
                @extend %font-10-sr; 
            }
        }
    }
    .btn-subscribe {
        width: max-content;
        height: 4rem;
        transition: .3s all;
        @extend %relative;
        @extend %p-3;
        @extend %half-radius;
        @extend %flex-c-c;
        @extend %men-blue-bg;
        &:hover {
            @extend %men-blue_light-bg;
        }
        .btn-text {
            @extend %pure-white-900;
            @extend %uppercase;
            @extend %font-10-sb;
        }
    }
    .errordiv {
        @extend %flex-c-c;
        @extend %mt-2;
        @extend %error-900;
    }
    .globalMsg {
        @extend %flex-c-c;
        @extend %mt-2;
        @extend %success-900;
    }
}
@include mq(col-md) {
    .waf-newsletter {
        .layout-wrapper {
            padding: var(--space-6);
        }
        .waf-head {
            .text {
                line-height: 2rem;
            }
            .social {
                &-item {
                    &:nth-child(2) {
                        a {
                            font-size: 1.8rem;
                        }
                    }
                }
                &-link {
                    &::before {
                        font-size: 1.4rem;
                    }
                }
            }
            .head-image {
                .image {
                    height: 2.5rem;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-newsletter {
        padding-inline: var(--space-0);
        margin-inline: auto;
        width: var(--container-max-width);
        @include gradient('main', 'lr');
        &::before {
            background-size: cover;
        }
        .layout-wrapper {
            gap: var(--space-2);
            @include flex-config(flex,null,null,center);
        }
        .waf-head {
            width: 50%;
            .head-image {
                .image {
                    height: 1.8rem;
                }
            }
            .text {
                width: 85%;
            }
        }
        .waf-body {
            width: 50%;
            margin-top: 0;
        }
        .form-data {
            flex-direction: row;
        }
    }
}