@use "../config/" as *;
.tab {
    &-section {
        @extend %mb-6;
    }
    &s {
        list-style: none;
        row-gap: var(--space-4);
        @extend %flex-wrap;
        @extend %flex;
        @extend %p-0;
        .filter-wrap {
            @extend %w-100;
        }
        .select-tab {
            flex: auto;
            border: none;
            justify-content: space-between;
            @extend %w-100;
            @extend %gap-2;
            .tab-text {
                @extend %font-14-pm;
                @extend %neutral-900;
            }
        }
    }
    &-select {
        height: 5rem;
        width: 30rem;
        border-radius: 0 0 var(--full-radius) var(--full-radius);
        @include border(1, neutral-300);
        @extend %neutral-50-bg;
    }
    &-name {
        border-radius: 0 0 var(--full-radius) var(--full-radius);
        height: 5rem;
        flex: 1;
        @include border(1, neutral-300);
        @extend %flex-c-c;
        @extend %font-20-pm;
        @extend %neutral-700;
        &.active {
            @extend %men_dark_trbl;
            @extend %pure-white-900;
        }
        .btn-text,.text{
            padding-top: .3rem
        }
    }
    &-wrap {
        @extend %flex;
        @extend %w-100;
    }
}
@media (min-width:$tablet-min-width) {
    .tab {
        &s {
            flex-wrap: nowrap;
            justify-content: space-between;
            gap: 14rem;
            .filter-wrap {
                order: 1;
                width: auto;
            }
        }
        &-select {
            width: 20rem;
        }
    }
}