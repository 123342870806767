@use "../config" as *;
.waf-home-scorecard {
    margin-bottom: var(--space-6);
    padding: 0 var(--space-4) var(--space-6);
    margin-inline: var(--space-2-neg);
    @extend %relative;
    .waf-head {
        .title {
            @extend %d-none;
        }
        .head-tab {
            a {
                height: 4rem;
                border-radius: 0 0 var(--full-radius) var(--full-radius);
                width: calc(100% - 4rem);
                @include position(null,null,0,var(--space-4));
                @extend %men_dark_tlbr;
                @extend %flex-c-c;
                @extend %font-12-sb;
                @extend %pure-white-900;
            }
        }
        .head-wrap {
            border-bottom: 0;
        }
    }
    .countdown-venue-wrapper,
    .live-match-footer,
    .team-venue,
    .end-match-footer,
    .shortname,
    .run-rate {
        @extend %d-none;
    }
    .card {
        &-head {
            border-bottom: .1rem solid var(--neutral-300);
            @extend %pb-3;
            @extend %flex-sb-c;
            .date {
                @extend %pure-white-900;
                @extend %font-10-sb;
                @include position(var(--space-5-neg),null,null,0);
            }
            .label {
                @extend %men-deep_blue;
                @extend %capitalize;
                @extend %font-14-pm;
            }
            .status {
                min-height: 2rem;
                @extend %gap-1;
                @extend %relative;
                @extend %px-2;
                @extend %flex-sb-c;
                @extend %pure-white-900;
                @extend %uppercase;
                @extend %rounded-radius;
                @extend %font-10-sb;
                &:before {
                    width: 1rem;
                    height: 1rem;
                    @include background(null, "svg/live.svg", no-repeat center/cover);
                }
            }
        }
        &-item {
            min-height: 24rem;
            @extend %pure-white-900-bg;
            @extend %full-radius;
            @extend %p-3;
        }
        &-footer {
            @extend %pt-3;
        }
        &-action-footer {
            @extend %pt-2;
        }
    }
    .match {
        &-info {
            column-gap: var(--space-2);
            @extend %pb-2;
            @extend %flex-c-n;
        }
        &-group {
            padding-left: .2rem;
            @extend %relative;
            &::after {
                content: '';
                width: .1rem;
                right: -.6rem;
                @extend %h-80;
                @extend %men-deep_blue-bg;
                @extend %pos-y-center;
            }
            .text {
                letter-spacing: var(--letter-space-neg-1);
                @extend %men-deep_blue;
                @extend %font-14-tsb;
            }
        }
        &-time {
            .meta {
                letter-spacing: var(--letter-space-neg-1);
                @extend %men-deep_blue;
                @extend %font-14-tsb;
            }
        }
        &-number {
            @extend %relative;
            &::after {
                content: '';
                width: .1rem;
                right: -.6rem;
                @extend %h-80;
                @extend %men-deep_blue-bg;
                @extend %pos-y-center;
            }
            .text,
            .number {
                letter-spacing: var(--letter-space-neg-1);
                @extend %men-deep_blue;
                @extend %font-14-tsb;
            }
        }
        &-venue {
            @extend %d-none;
        }
    }
    .team {
        width: 42%;
        min-height: 7rem;
        @extend %flex-column-n-fe;
        @extend %relative;
        &.team-b {
            padding-left: 5rem;
            align-items: flex-start;
            .team-img {
                left: 0;
                right: unset;
            }
            .team-score {
                align-items: flex-start;
            }
        }
        &.team-a {
            padding-right: 5rem;
            .team-name {
                line-height: 1;
                @extend %text-right;
            }
        }
        &-wrap {
            border-bottom: .1rem solid var(--neutral-300);
            @extend %py-3;
            @extend %flex-c-c;
        }
        &-divider {
            width: 16%;
            .text-vs {
                @extend %pt-1;
                @extend %men-deep_blue;
                @extend %text-center;
                @extend %uppercase;
                @extend %font-20-pb;
            }
        }
        &-name {
            .name {
                @extend %uppercase;
                @extend %men-deep_blue;
                @extend %font-14-pm;
            }
        }
        &-img {
            width: 4.5rem;
            height: 4.5rem;
            right: 0;
            @extend %pos-y-center;
            @extend %p-1;
            @extend %circle-radius;
            @extend %neutral-10-bg;
            .team-logo {
                @extend %circle-radius;
            }
        }
        &-score {
            // @extend %pt-1;
            @extend %flex-column-n-fe;
            // @extend %gap-1;
            .score {
                letter-spacing: 0;
                @extend %men-deep_blue;
                @extend %font-16-tb;
                &.no-score {
                    // @extend %font-16-pb;
                }
            }
        }
        &-extra {
            .overs {
                letter-spacing: 0;
                @extend %men-deep_blue;
                @extend %font-12-tsb;
            }
        }
        &-status {
            @extend %text-center;
            .text {
                @extend %pt-1;
                @extend %font-14-pm;
                @extend %men-deep_blue;
            }
        }
        &-data-wrap {
            @extend %mt-auto;
        }
    }
    .footer-wrap {
        @extend %pb-0;
    }
    .swiper {
        position: static;
    }
    .swiper-button {
        @extend %pure-white-900-bg;
        @extend %pos-y-center;
        top: calc(50% - 1.5rem);
        &-next {
            right: var(--space-1);
            &::after {
                @extend %men-deep_blue;
            }
            &.swiper-button-disabled {
                &::after {
                    @extend %neutral-600;
                }
            }
        }
        &-prev {
            left: var(--space-1);
            &::after {
                @extend %men-deep_blue;
            }
            &.swiper-button-disabled {
                &::after {
                    @extend %neutral-600;
                }
            }
        }
    }
    .waf-body {
        border-radius: var(--full-radius) var(--full-radius) 0 0;
        @extend %pt-5;
        @extend %pb-6;
        @extend %px-4;
        @extend %men-deep_blue-bg;
    }
    .live {
        .status {
            @extend %success-900-bg;
            &:before {
                content: '';
            }
        }
    }
    .upcoming {
        .status {
            @extend %men-deep_blue;
            @extend %neutral-50-bg;
        }
    }
    .recent {
        .status {
            @extend %error-600-bg;
        }
    }
    .btn-livestream {
        width: max-content;
        z-index: var(--z-default);
        @extend %relative;
        @extend %mx-auto;
        @extend %flex;
        .btn-text {
            @extend %gap-1;
            @extend %flex-sb-c;
            @extend %font-10-sb;
            @extend %uppercase;
            @extend %men-deep_blue;
            &:after {
                width: 1.5rem;
                height: 1.5rem;
                padding-left: .2rem;
                @extend %circle-radius;
                @extend %flex-c-c;
                @extend %pure-white-900;
                @extend %men-deep_blue-bg;
                @include icon(right-fill-arrow,8);
            }
        }
    }
    .btn-scorecard {
        @extend %pos-tl;
        @extend %w-100;
        @extend %h-100;
        .btn-text {
            @extend %font-0;
        }
    }
    .match-date {
        @extend %d-none;
        @extend %pure-white-900;
        @extend %font-10-sb;
        @extend %uppercase;
    }
    .content-wrap {
        @extend %pt-6;
    }
}
@include mq(col-lg) {
    .waf-home-scorecard {
        padding-inline: 0;
        padding-block: 7rem 0;
        margin: 0;
        width: 40rem;
        background: var(--men-deep_blue);
        height: calc(var(--window-inner-height) - 7rem);
        transition: .5s ease-in-out;
        z-index: var(--z-default);
        @include position(-7rem,var(--space-3),null,null);
        .waf-head  {
            .head-tab a {
                z-index: 2;
                left: 0;
                width: 100%;
                border-radius: 0;
            }
        }
        .waf-body {
            border-radius: 0;
            padding-top: var(--space-3);
            height: calc(var(--window-inner-height) - 14.5rem);
            overflow-y: auto;
            @include custom-scroll;
        }
        .match-date {
            display: block;
        }
        .swiper-button {
            display: none;
        }
        .team-name {
            .name {
                font-size: 1.6rem;
            }
        } 
        .card {
            &-section {
                flex-direction: column;
            }
            &-list {
                margin-bottom: var(--space-4);
                gap: var(--space-3);
                @include flex-config(flex,column,null,null);
            }
            &-head {
                .date {
                    display: none;
                }
            }
        }
        .content-wrap {
            padding-top: 0;
        }
    }
}