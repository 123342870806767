@forward "./main-scss/";
@forward "./scorestrip/key-events-scorestrip";
@forward "./listing/listing-common";
@forward "./listing/home-listing";
@forward "./listing/latest-news-01";
@forward "./partials/newsletter";
@forward "./partials/home-collaboration";
@forward "./gallery/home-gallery";
@forward "./partials/our-events";
@forward "./scorestrip/home-live-scorecard";
@forward "./partials/hero-banner";
@use "./config/" as *;

.waf-row-banner {
    padding-top: 11rem;
    @extend %main_lr;
    @extend %hidden; 
}
@include mq(col-lg) {
    .waf-row-banner {
        padding-top: 14.5rem;
        height: var(--window-inner-height);
    }
}