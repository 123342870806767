@use "../config/" as *;

.waf-banner-title {
    margin-block: var(--space-4);
    @extend %p-0;
    @extend %relative;
    .title {
        font-style: italic;
        @extend %uppercase;
        @extend %text-center;
        @extend %font-30-pb;
        @extend %pure-white-900;
        @extend %d-none;
    }
    .image {
        width: 50%;
        object-fit: cover;
        object-position: center;
        @extend %mx-auto;
    }
}
@include mq(col-md) {
    .waf-banner-title {
        margin-block: 0;
        .image {
            margin-inline: 16px;
            width: calc(100% - 3.2rem);
        }
    }
}
@include mq(col-lg) {
    .waf-banner-title {
        margin-bottom: 0;
        width: calc(100% - 40rem);
        height: calc(var(--window-inner-height) - 14rem);
        overflow: hidden;
        .layout-wrapper {
            max-width: unset;
            height: 100%;
        }
        .title {
            width: 50%;
            margin-inline: auto;
            font-size: 7.4rem;
        }
        .image {
            width: 100%;
            height: 100%;
            margin-inline: 0;
        }
    }
}